import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import { ProductGroup } from '@/api/product';
import { useRouter } from 'next/router';
import Image from 'next/image';
import SkeletonTabs from '../skeleton/SkeletonTabs';
import { isProduction } from '@/config';

interface ProductCategory {
    category_id: number;
    category_icon_small: string;
    category_title: string;
    category_type: string;
}

const TabCategoryProduct: React.FC = () => {
    const router = useRouter();
    const category_id = router.query.category_id as string || "";

    const [category, setCategory] = useState<ProductCategory[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await ProductGroup();
            if (response.success) {
                setCategory(response.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        // Split the newValue by the hyphen and extract the second part (category_type)
        const [categoryId, categoryType] = newValue.split('-');

        // Filter matching category
        const categoryArray = Object.values(category);
        const categoryItems:any = categoryArray.map((item, idx) => {
            return {
                item_id: item.category_id,
                item_name: item.category_title,
                item_type: item.category_type,
                index: idx,
            }
        })
        const filteredData = categoryItems.filter((cate: any) => cate.item_id === Number(categoryId));

        if (filteredData && filteredData.length > 0) {

            // Update dataLayer for Google Analytics
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
            window.dataLayer.push({
                event: "select_category",
                ecommerce: {
                    category_id: filteredData[0]?.item_id,
                    category_name: filteredData[0]?.item_name,
                    category_type: filteredData[0]?.item_type,
                    // items: filteredData,
                },
            });

            // Log data in non-production environments
            if (!isProduction) {
                console.log("DataLayer updated:", {
                    event: "select_category",
                    ecommerce: {
                        category_id: filteredData[0]?.item_id,
                        category_name: filteredData[0]?.item_name,
                        category_type: filteredData[0]?.item_type,
                        // items: filteredData,
                    },
                });
            }

        } else {
            // Handle the case when filteredData is empty or undefined
            if (!isProduction) {
                console.warn("No matching category data to push to dataLayer.");
            }
        }
        
        const currentPath = '/search/allsearch';
        const currentQuery = { 
            ...router.query, 
            category_id: categoryId, // Use categoryId in query
            keyword: router.query.keyword || '', 
            product_type: categoryType // Use categoryType in query 
        };
        
        router.push({
            pathname: currentPath,
            query: currentQuery,
        }, undefined, { shallow: true });
    };

    return (
        <Grid container mt={1}>
            {category.length > 0 && (
                <>
                    {!isLoading ? (
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }} item xs={12}>
                            <Tabs
                                // value={category_id}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable product category tabs"
                                allowScrollButtonsMobile
                                TabIndicatorProps={{
                                    style: { backgroundColor: 'white' },
                                }}
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 },
                                    },
                                    flexWrap: 'nowrap',
                                    "& .Mui-selected .tab-content": {
                                        color: '#292929',
                                    }
                                }}
                            >
                                {category.map((data: ProductCategory) => (
                                    <Tab
                                        disableRipple
                                        key={data.category_id}
                                        label={
                                            <Box
                                                className="tab-content"
                                                sx={{
                                                    color: '#717171',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    whiteSpace: 'nowrap',
                                                    flexShrink: 0,
                                                }}
                                            >
                                                <Image
                                                    src={data.category_icon_small}
                                                    alt={`ไอคอนสำหรับหมวดหมู่ ${data.category_title}`} 
                                                    width={30}
                                                    height={30}
                                                    layout="fixed"
                                                    objectFit="contain"
                                                    // priority
                                                    loading="lazy"
                                                    placeholder="blur"
                                                    blurDataURL={data.category_icon_small}
                                                />
                                                <Typography
                                                    variant="h3"
                                                    sx={{
                                                        mt: 1,
                                                        mb: 1,
                                                        fontSize: 14,
                                                        '&:hover': { color: '#000000' },
                                                    }}
                                                >
                                                    {data.category_title}
                                                </Typography>
                                            </Box>
                                        }
                                        value={`${data.category_id}-${data.category_type}`}
                                        sx={{
                                            flexShrink: 0,
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                    ) : (
                        <Grid item xs={12} my={4}>
                            <SkeletonTabs />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default TabCategoryProduct;
