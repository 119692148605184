import { Box, Button, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react'
import Grid from "@mui/material/Unstable_Grid2";

type Props = {}

export default function countdownCampaigncomingSoonTime({
    datacampaignComingSoon, alignment
}: any) {

    const campaignStartTo = datacampaignComingSoon?.campaign_start

    // const campaignStartTo = "2024-11-07 11:01:00"

    const [timeRemaining, setTimeRemaining] = useState<{ days: number; hours: number; minutes: number; seconds: number }>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const [endTime, setEndTime] = useState<Date | null>(dayjs(campaignStartTo).toDate());
    const [statusCampaignEnd, setStatusCampaignEnd] = useState(false)
    const now = new Date();
    const router = useRouter();

    const calculateTimeRemaining = (endTime: Date): { days: number; hours: number; minutes: number; seconds: number } => {

        const now = new Date().getTime();
        const distance = endTime.getTime() - now;

        if (distance <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    useEffect(() => {
        if (!endTime) return;
        if (campaignStartTo !== null) {
            if (endTime <= now) {
                setStatusCampaignEnd(false)
                setTimeRemaining({ hours: 0, minutes: 0, seconds: 0, days: 0 });
                return;
            }
            const intervalId = setInterval(() => {
                const remaining = calculateTimeRemaining(endTime);
                setTimeRemaining(remaining);

                if (remaining.hours <= 0 && remaining.minutes <= 0 && remaining.seconds <= 0) {
                    clearInterval(intervalId);
                    setStatusCampaignEnd(true)
                    sessionStorage.setItem('hasReloaded', 'true');

                    clearInterval(intervalId);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setStatusCampaignEnd(true)
        }
    }, [endTime, router]);
    return (
        <>

            {statusCampaignEnd === false && (
                <Grid container
                    sx={{
                        gap: "4px",
                        float: {
                            lg: `${alignment}`, md: "right", sm: "right"
                        },
                        justifyItems: "center"
                    }}>
                    <Typography
                        sx={{
                            fontFamily: ' NotoSansThai-SemiBold',
                            mr: 1,
                            paddingTop: "10px",
                        }}>
                        จะเริ่มในอีก
                    </Typography>

                    {timeRemaining.days === 0 ? (
                        <></>
                    ) : (
                        <>
                            <Grid sx={{ ml: { xs: "0px", md: "10px" } }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontSize: '12px',
                                        padding: '4px',
                                        borderRadius: '6px',
                                        display: {
                                            xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                                        },
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        minWidth: "35px",
                                        borderColor: "#DB5A00",
                                        bgcolor: "#DB5A00",
                                        '&:hover': { borderColor: "#b34a00", bgcolor: "#b34a00" },
                                        color: "#ffffff"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "16px", pr: "2px",
                                            display: { xs: "inline-block" }
                                        }}>
                                        {timeRemaining.days}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "10px",
                                            display: { xs: "inline-block" }
                                        }}>
                                        วัน
                                    </Typography>
                                </Button>
                            </Grid>
                            <Typography sx={{ mt: { xs: "3px", md: "10px" } }}>:</Typography>
                        </>
                    )}

                    <Grid>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '12px', padding: '4px 4px', borderRadius: '6px', display: {
                                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                                }, flexDirection: 'column', alignItems: 'center', textAlign: 'center', minWidth: "35px", borderColor: "#DB5A00", bgcolor: "#DB5A00", '&:hover': { borderColor: "#b34a00", bgcolor: "#b34a00" }, color: "#ffffff"
                            }}
                        >
                            <Typography sx={{
                                fontSize: "16px", pr: "2px",
                                display: { xs: "inline-block" }
                            }}>{timeRemaining.hours}</Typography>
                            <Typography sx={{
                                fontSize: "10px",
                                display: { xs: "inline-block" }
                            }}>ชั่วโมง</Typography>
                        </Button>
                    </Grid>
                    <Typography sx={{ mt: { xs: "3px", md: "10px" } }}>:</Typography>
                    {/* ส่วนของนาที */}
                    <Grid>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '12px', padding: '4px', borderRadius: '6px',
                                display: {
                                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                                },
                                flexDirection: 'column',
                                alignItems: 'center', textAlign: 'center', minWidth: "35px",
                                borderColor: "#DB5A00", bgcolor: "#DB5A00",
                                '&:hover': { borderColor: "#b34a00", bgcolor: "#b34a00" }, color: "#ffffff"
                            }}
                        >
                            <Typography sx={{
                                fontSize: "16px", pr: "2px",
                                display: { xs: "inline-block" }
                            }}>{timeRemaining.minutes}</Typography>
                            <Typography sx={{
                                fontSize: "10px",
                                display: { xs: "inline-block" }
                            }}>นาที</Typography>
                        </Button>
                    </Grid>

                    <Typography sx={{ mt: { xs: "3px", md: "10px" } }}>:</Typography>
                    {/* ส่วนของวินาที */}
                    <Grid>
                        <Button
                            variant="contained"
                            sx={{
                                fontSize: '12px', padding: '4px', borderRadius: '6px',
                                display: {
                                    xs: "inline-block", sm: "flex", md: "flex", lg: "flex"
                                },
                                flexDirection: 'column',
                                alignItems: 'center', textAlign: 'center', minWidth: "35px",
                                borderColor: "#DB5A00", bgcolor: "#DB5A00",
                                '&:hover': { borderColor: "#b34a00", bgcolor: "#b34a00" }, color: "#ffffff"
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "16px", pr: "2px",
                                    display: { xs: "inline-block" }
                                }}>
                                {timeRemaining.seconds}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "10px",
                                    display: { xs: "inline-block" }
                                }}>
                                วินาที
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    )
}