import React from 'react';
import { Grid, Box, Skeleton, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const SkeletonTabs: React.FC = () => {
    return (
        <Grid xs={12} md={12} lg={12}>
            <Box sx={{ overflowX: 'auto', maxWidth: '100%', width: "100%" }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between'
                    }}
                >
                    <IconButton
                        disableFocusRipple
                        disableRipple
                        style={{ fontSize: '12px', width: "50px", borderRadius: "0", zIndex: "9", backgroundImage: "linear-gradient(to bottom right, #ffffff, #ffffff54)" }}
                    >
                        <FontAwesomeIcon style={{ border: "1px solid #d5d5d5", width: "12px", padding: "7px", borderRadius: "50px", backgroundColor: "#ffffff" }} icon={faChevronLeft} />
                    </IconButton>
                    {Array.from(new Array(8)).map((_, idx) => (
                        <Skeleton
                            key={idx}
                            variant="rectangular"
                            width={120}
                            height={40}
                            sx={{
                                borderRadius: '25px',
                                flexShrink: 0,
                                px: '5px'
                            }}
                        />
                    ))}
                    <IconButton
                        disableFocusRipple
                        disableRipple
                        style={{ fontSize: '12px', width: "50px", borderRadius: "0", zIndex: "9", backgroundImage: "linear-gradient(to bottom right, #ffffff, #ffffff54)" }}
                    >
                        <FontAwesomeIcon style={{ border: "1px solid #d5d5d5", width: "12px", padding: "7px", borderRadius: "50px", backgroundColor: "#ffffff" }} icon={faChevronRight} />
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    );
};

export default SkeletonTabs;
